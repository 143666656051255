<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'

const model = defineModel<boolean>()

const emit = defineEmits<{
  (e: 'confirmExit'): void
}>()

function close (): void {
  model.value = false
}

function confirmExit (): void {
  emit('confirmExit')

  close()
}
</script>

<template>
  <CustomModalWindow v-model="model" class="early-exit-modal-window" :click-to-close="false" disable-close-icon>
    <template #title>
      Закрыть окно без сохранения изменений?
    </template>

    <div class="early-exit-modal-window__buttons">
      <BasicButton
        class="early-exit-modal-window__button"
        color="body-background"
        title="Нет, остаться"
        @click="close"
      />
      <BasicButton
        class="early-exit-modal-window__button"
        color="red"
        title="Да, закрыть"
        @click="confirmExit"
      />
    </div>
  </CustomModalWindow>
</template>

<style lang="scss" scoped>
.early-exit-modal-window {
  &__button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__buttons {
    display: flex;
  }
}
</style>
